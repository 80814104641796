@font-face {
  font-family: "futura";
  src: url("../fonts/FuturaPTMedium.otf");
}
@font-face {
  font-family: "roboto";
  src: url("../fonts/Roboto-Regular.ttf");
}

$colour1: #fffff8;
$colour2: #3b2f2f;
$colour3: #128482;
$colour4: #d0491e;

$font1: "futura";
$font2: "roboto";

$header-height: 75px;

$padding1: 0 2%;
