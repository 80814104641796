@import "../../display/style/style.scss";

// BUTTONS
.button {
  background-color: rgba($color: #000000, $alpha: 0);
  border-width: 2px;
  cursor: pointer;
  font-size: 21px;
  padding: 6px 2%;
}
.button1 {
  width: 100%;
}
.button2,
.button3 {
  border-color: $colour1 !important;
  color: $colour1 !important;
  text-align: center;
  transition: 0.2s background-color ease-in-out, 0.4s color ease-in-out,
    0.6s border-color ease-in-out;
  width: 145px;
}
.button2 {
  background-color: $colour3;
}
.button3 {
  background-color: $colour4;
}
.button2:hover {
  background-color: rgba($color: #000000, $alpha: 0);
  border-color: $colour3 !important;
  color: $colour3 !important;
}
.button3:hover {
  background-color: rgba($color: #000000, $alpha: 0);
  border-color: $colour4 !important;
  color: $colour4 !important;
}

// CARDS
.card {
  margin: 21px auto;
  .card-title {
    color: $colour3;
    font-weight: bold;
    text-align: center;
  }
}
.card1 {
  width: 300px;
  .card-title {
    font-size: 21px;
  }
  .card1-image-container {
    border-width: 2px;
    max-height: 140px;
    overflow: hidden;
    .card1-image {
      margin-left: 0;
      margin-top: 0;
      transition: 0.3s width ease-in-out, 0.3s margin-left ease-in-out,
        0.3s margin-top ease-in-out;
      width: 100%;
    }
  }
  .card1-image-container:hover {
    .card1-image {
      margin-left: -5%;
      margin-top: -5%;
      width: 110%;
    }
  }
  .card1-text-container {
    .card-title {
      font-size: 28px;
    }
    .card1-description {
      max-height: 120px;
      min-height: 120px;
      overflow: hidden;
      padding: 0 2%;
      text-align: justify;
    }
    .card1-buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 7px;
    }
  }
}
.card2 {
  cursor: pointer;
  width: 150px;
  .card-image-container {
    margin-top: 14px;
    min-height: 120px;
    .card2-image {
      margin-left: 50%;
      transform: translateX(-50%);
      width: 70%;
    }
  }
  .card2-level {
    color: $colour4;
    font-size: 21px;
    font-weight: bold;
    margin-top: -7px;
    text-align: center;
  }
}
.card3 {
  margin-bottom: 42px;
  max-width: 600px;
  .card3-image-container {
    max-height: 140px;
    overflow: hidden;
    .card3-image {
      width: 100%;
    }
  }
  .card3-text-container {
    max-height: 160px;
    overflow: hidden;
    .card-title {
      font-size: 35px;
      line-height: 35px;
    }
    .card3-date {
      color: $colour4;
      font-size: 18px;
      font-weight: 800;
      margin-top: -7px;
      text-align: center;
    }
    .card3-text {
      font-size: 18px;
      padding: 0 2%;
      text-align: justify;
    }
  }
}
.card4 {
  cursor: pointer;
  position: relative;
  .card4-link:first-child {
    font-size: 90px;
  }
  .card4-hover {
    background-color: rgba($color: $colour4, $alpha: 0.7);
    border-radius: 6px;
    color: $colour1;
    font-size: 18px;
    opacity: 0;
    padding: 3px 0;
    position: absolute;
    text-align: center;
    top: 35px;
    transition: 0.3s ease-in-out opacity;
    width: 100%;
  }
}
.card4:hover {
  .card4-hover {
    opacity: 1;
  }
}

// INPUTS
.input {
  border-width: 2px;
  font-size: 21px;
  margin-bottom: 6px;
  padding: 6px 2%;
  width: 100%;
}
.input2 {
  height: 210px;
}

// LINK
.link {
  font-size: 21px;
}
.link1 {
  flex: 1;
  margin-right: 75px;
}

.route {
  .route-title {
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
  }
  .route-content {
    min-height: calc(100vh - 125px);
    max-height: calc(100vh - 125px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
