@import "../../../display/style/style.scss";

// BLOG ITEM
.blog-item {
  margin-right: 6px;
  .blog-item-image {
    margin-left: 50%;
    margin-top: 21px;
    max-width: 600px;
    transform: translateX(-50%);
    width: 100%;
  }
  .blog-item-title {
    color: $colour3;
    font-size: 42px;
    text-align: center;
  }
  .blog-item-date {
    color: $colour4;
    font-size: 21px;
    font-weight: 800;
    text-align: center;
  }
  .blog-item-text {
    font-size: 21px;
    text-align: justify;
    padding: 21px 2%;
  }
}

// CONTACT
.contact-links-container {
  display: flex;
  justify-content: space-around;
  margin-top: 21px;
  max-width: 400px;
  .contact-link {
    cursor: pointer;
    .contact-link-image {
      width: 60px;
    }
  }
}
.contact-form-container {
  margin-top: 21px;
  max-width: 600px;
  .contact-form-greet {
    font-size: 21px;
    text-align: center;
  }
  .contact-form-error-container {
    color: $colour4;
    height: 21px;
    text-align: center;
  }
}

// CURRICULUM VITAE
.curriculum-vitae {
  text-align: center;
  .curriculum-vitae-link {
    color: $colour4;
    font-size: 21px;
    font-weight: 800;
    margin-bottom: 7px;
  }
}

// HOME
.home-links-container {
  margin: 21px auto 0 auto;
  text-align: center;
  .home-link {
    .home-link-image {
      margin: 0 7px;
      width: 70px;
    }
  }
}
.home-title-container {
  margin: -14px auto 0 auto;
  max-width: 600px;
  .home-title {
    font-size: 58px;
    text-align: center;
    .dougie {
      color: $colour3;
      font-family: $font1;
      margin-right: -7px;
    }
    .hawes {
      color: $colour4;
      font-family: $font1;
      margin-left: -7px;
    }
  }
  .home-subtitle {
    font-size: 29px;
    text-align: center;
  }
}
.mern-container {
  display: flex;
  height: 210px;
  justify-content: space-around;
  margin: 21px auto 0 auto;
  max-width: 300px;
  .mern-item-container {
    height: 70px;
    width: 21%;
    .mern-item-image {
      width: 100%;
    }
    .mern-item-text {
      font-size: 70px;
      margin-top: -14px;
      text-align: center;
    }
  }
}
.home-work-samples-container {
  display: flex;
  flex-wrap: wrap;
  margin: -70px auto 0 auto;
  max-width: 950px;
  width: 100%;
  .home-work-sample {
    border-width: 2px;
    cursor: pointer;
    margin-bottom: 14px;
    max-height: 140px;
    overflow: hidden;
    position: relative;
    width: 300px;
    .home-work-sample-image {
      width: 100%;
    }
    .home-work-sample-text-container {
      bottom: 0;
      position: absolute;
      width: 100%;
      .home-work-sample-category {
        color: $colour4;
        font-family: $font1;
        margin-bottom: -14px;
        text-shadow: 1px 1px 1px $colour3, 1px -1px 1px $colour3,
          -1px 1px 1px $colour3, -1px -1px 1px $colour3;
        font-size: 42px;
      }
      .home-work-sample-title {
        color: $colour1;
        font-size: 21px;
        text-shadow: 1px 1px 1px $colour2, 1px -1px 1px $colour2,
          -1px 1px 1px $colour2, -1px -1px 1px $colour2;
      }
      .home-work-sample-category,
      .home-work-sample-title {
        opacity: 0;
        text-align: center;
        transition: 0.6s opacity ease-in-out;
      }
    }
  }
  .home-work-sample:hover {
    .home-work-sample-category,
    .home-work-sample-title {
      opacity: 1;
    }
  }
}
.home-social-media-container {
  .home-social-media-title {
    color: $colour3;
    margin-bottom: 14px;
    text-align: center;
  }
  .home-social-media-links-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 700px;
  }
}

// SKILLS
.skills-grid {
  display: flex;
  flex-wrap: wrap;
}

// SKILL MODAL
.skill-modal-container {
  .skill-modal {
    border-width: 2px;
    left: 50%;
    min-height: 420px;
    position: absolute;
    transform: translateX(-50%);
    top: 42px;
    width: 300px;
    z-index: 999;
    .skill-modal-title {
      color: $colour3;
      font-size: 42px;
      font-weight: bold;
      text-align: center;
    }
    .skill-modal-image {
      margin-left: 50%;
      transform: translateX(-50%);
      width: 140px;
    }
    .skill-modal-level {
      color: $colour4;
      font-size: 21px;
      font-weight: bold;
      margin-top: -7px;
      text-align: center;
    }
    .skill-modal-details {
      padding: 18px;
      text-align: justify;
    }
    .skill-modal-close {
      cursor: pointer;
      font-weight: 800;
      font-size: 21px;
      position: absolute;
      right: 12px;
      top: 6px;
    }
  }
  .skill-modal-lightmode {
    background-color: $colour1;
  }
  .skill-modal-darkmode {
    background-color: $colour2;
  }
  .skill-modal-background {
    background-color: rgba($color: $colour2, $alpha: 0.42);
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

// WORKS
.work-grid {
  display: flex;
  flex-wrap: wrap;
}

// WORK ITEM
.return-link {
  color: $colour4 !important;
  font-size: 21px;
  font-weight: 800;
  padding: 14px 0;
}
.work-item {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1%;
  .work-item-column {
    margin-top: 21px;
    max-width: 650px;
    min-width: 300px;
    width: 100%;
    .work-item-image {
      margin-bottom: 7px;
      position: relative;
      .work-item-toggle {
        background-color: $colour4;
        border-radius: 50%;
        cursor: pointer;
        font-size: 42px;
        position: absolute;
        top: 42%;
      }
      .work-item-toggle-left {
        left: 1%;
      }
      .work-item-main-image {
        aspect-ratio: 20 / 9;
        border-width: 2px;
        width: 100%;
      }
      .work-item-toggle-right {
        right: 1%;
      }
    }
    .work-item-thumbnails {
      display: grid;
      grid-gap: 7px;
      grid-template-columns: repeat(3, 1fr);
      .work-item-thumbnail {
        cursor: pointer;
        width: 100%;
      }
    }
    .work-item-title {
      color: $colour3;
      font-size: 42px;
      text-align: center;
    }
    .work-item-links {
      display: flex;
      flex-wrap: wrap;
      .work-item-link {
        color: $colour1;
        font-size: 21px;
        margin-bottom: 7px;
        padding: 7px;
        text-align: center;
        width: 100%;
      }
      .work-item-link-site {
        background-color: $colour3;
      }
      .work-item-link-code {
        background-color: $colour4;
      }
    }
    .work-item-description {
      font-size: 21px;
      text-align: justify;
    }
  }
}
