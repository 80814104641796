@import "../../../display/style/variables";

// CREATE WORK
.sign-out {
  color: $colour4;
  cursor: pointer;
  font-size: 21px;
  font-weight: 800;
  text-align: center;
}
